import React from 'react'

import ShadowProfImg from './inc/logo-shadow.png'

import ShadowHeaderCSS from './shadowme-header.module.css'

const ShadowPostHeader = ({ totalCount }) => (
  <header>
    <div className={ShadowHeaderCSS.profile}>
      <div className={ShadowHeaderCSS.username}>
        {' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/remcaphoto_shadow/"
        >
          remcaphoto_shadow
        </a>
      </div>
      <div className={ShadowHeaderCSS.profileImage}>
        <img src={ShadowProfImg} alt="Profile Pic Shadow"></img>
      </div>

      <div className={ShadowHeaderCSS.profileStats}>
        <div className={ShadowHeaderCSS.profileStatCol}>
          <div className={ShadowHeaderCSS.profileStatCount}>{totalCount}</div>
          Posts
        </div>
        <div className={ShadowHeaderCSS.profileStatCol}>
          <div className={ShadowHeaderCSS.profileStatCount}>112</div> Followers
        </div>
        <div className={ShadowHeaderCSS.profileStatCol}>
          <div className={ShadowHeaderCSS.profileStatCount}>1</div> Following
        </div>
      </div>

      <div className={ShadowHeaderCSS.profileBio}>
        <div className={ShadowHeaderCSS.profileRealName}>
          <span>Shadow </span>
          <span role="img" aria-label="emoji-shadow">
            👤
          </span>
        </div>
        <div>
          Hi, I'm{' '}
          <a
            style={{ color: '#20760ab' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/remcaphoto/"
          >
            @remcaphoto
          </a>
          's shadow, always following him.
        </div>
      </div>
    </div>
  </header>
)
export default ShadowPostHeader
