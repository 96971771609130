import React from 'react'
import Moment from 'react-moment'

import ShadowPostInfoCSS from './shadowme-gal-item-info.module.css'

const ShadowItemInfo = ({ item }) => (
  <div class="gallery-item-info">
    <div className={ShadowPostInfoCSS.iconsbar}>
      <div className={ShadowPostInfoCSS.heart}></div>
      <div className={ShadowPostInfoCSS.bubble}></div>
      <div className={ShadowPostInfoCSS.send}></div>
    </div>

    <div style={{ fontSize: '16px' }} className="info-like">
      Liked by
      <a
        style={{ margin: '0 3px', fontWeight: '600' }}
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/remcaphoto/"
      >
        remcaphoto
      </a>
      and <span style={{ fontWeight: '600' }}>{item.likes} others</span>
    </div>
    <div
      style={{
        fontWeight: '600',
        display: 'inline-block',
        marginRight: '6px',
      }}
      className="info-name"
    >
      remcaphoto_shadow
    </div>
    <div style={{ display: 'inline-block' }} className="info-txt">
      {item.txt}
    </div>
    <div style={{ fontSize: '12px' }} className="info-date">
      <Moment fromNow>{item.date}</Moment>
    </div>
  </div>
)
export default ShadowItemInfo
