import React from 'react'
import Img from 'gatsby-image'

import ShadowImgCSS from './shadowme-gal-item-img.module.css'

const ShadowItemImg = ({ image }) => (
  <>
    <Img
      title=""
      fluid={image.shadowgrid.fluid}
      className={ShadowImgCSS.galleryImage}
    />
  </>
)
export default ShadowItemImg
