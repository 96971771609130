import React from 'react'

import ShadowProfImg from './inc/logo-shadow.png'

import ShadowPostHeaderCSS from './shadowme-post-header.module.css'

const ShadowPostHeader = () => (
  <div className="post-header">
    <div className={ShadowPostHeaderCSS.profile}>
      <div className={ShadowPostHeaderCSS.profileImage}>
        <img src={ShadowProfImg} alt="Profile Pic Shadow"></img>
      </div>
      <div className={ShadowPostHeaderCSS.username}>remcaphoto_shadow</div>
    </div>
  </div>
)

export default ShadowPostHeader
