import React from 'react'

import ShadowImg from './shadowme-gal-item-img'
import ShadowInfo from './shadowme-gal-item-info'
import ShadowPostHeader from '../../../components/custom/shadowme/shadowme-post-header'

const ShadowItem = ({ item }) => (
  <>
    <ShadowPostHeader />
    <a
      href={`#${item.image.name}`}
      class="gallery-item"
      id={item.image.name}
      tabIndex="0"
    >
      <ShadowImg image={item.image} />
    </a>{' '}
    <ShadowInfo item={item} />
  </>
)
export default ShadowItem
