import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../../components/layout'
import Content from '../../../components/content'
import Sidebar from '../../../components/Sidebar'

import ShadowPhoneScreen from '../../../components/custom/shadowme/shadowme-phone-screen'
import ShadowItem from '../../../components/custom/shadowme/shadowme-gal-item'
import ShadowHeader from '../../../components/custom/shadowme/shadowme-header'

import '../../../components/custom/shadowme/shadowme.css'
import '../../../components/custom/shadowme/shadowme-phone.css'

var today = new Date()
var Hours = ('0' + today.getHours()).slice(-2)
var Minutes = ('0' + today.getHours()).slice(-2)
var time = Hours + ':' + Minutes

const ShadowmePage = ({
  data: {
    allShadowmeJson: { edges, totalCount },
  },
}) => {
  const Item = edges.map(edge => (
    <ShadowItem key={edge.node.id} item={edge.node.item} />
  ))
  return (
    <>
      <Layout place="special">
        <Sidebar />
        <Content>
          <style>
            @import
            url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
          </style>
          <div className="marvel-device iphone-x">
            <div className="topbarleft">{time}</div>
            <div className="topbarright"></div>
            <div className="notch">
              <div className="camera"></div>
              <div className="speaker"></div>
            </div>
            <div className="top-bar"></div>
            <div className="sleep"></div>
            <div className="bottom-bar"></div>
            <div className="volume"></div>
            <div className="overflow">
              <div className="shadow shadow--tr"></div>
              <div className="shadow shadow--tl"></div>
              <div className="shadow shadow--br"></div>
              <div className="shadow shadow--bl"></div>
            </div>
            <div className="inner-shadow"></div>
            <ShadowPhoneScreen Item={Item}>
              <ShadowHeader totalCount={totalCount} />
            </ShadowPhoneScreen>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export const ShadowQuery = graphql`
  query allshdowmeimages {
    allShadowmeJson(sort: { fields: item___image___name, order: DESC }) {
      totalCount
      edges {
        node {
          id
          item {
            image {
              shadowgrid: childImageSharp {
                fluid(maxWidth: 375, cropFocus: ATTENTION, quality: 70) {
                  ...GatsbyImageSharpFluid
                }
              }
              name
            }
            txt
            likes
            date
          }
        }
      }
    }
  }
`

export default ShadowmePage
